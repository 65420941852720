import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  EditRequest, ProfileModal, CardOrderSchedule
} from "../../components";
import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { useStore } from "../../store";
import { KitchenOrder, KitchenOrderClient, Sector } from "../../types";
import useStyles from "./styles";
import { useFetchAllOrdersForSchedule, useGetSectorsActive } from "../../api/orders/hooks";
import { SearchableInput } from "../../components/searchableinput";
import { deliverOrder } from "../../services";
import { getClientNameForOrder } from "../../utils/getClientNameForOrder";
import { replaceSpecialCharacters } from "../../utils/strings";
import { usePrinter } from "../../hooks/usePrinter";
import { Select } from "../../components/Select";
import { useEntity } from "../../hooks/useEntity";
import CustomInput from "../../components/CustomInput";
import AlphabetIndex from "./AlphabetIndex";

export const ScheduledOrder: React.FC = () => {
  const Styles = useStyles();

  const queryClient = useQueryClient();

  const [clientProfile, setClientProfile] = useState<KitchenOrderClient | null>(null);
  const [entityId, setEntityId] = useState<string>('');
  const [orders, setOrders] = useState<any>([]);
  const [sectorsList, setSectorsList] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<KitchenOrder | null>(null);
  const [sectorSelected, setSectorSelected] = useState("all");
  const [sectorId, setSectorId] = useState("all");
  const [isPlaceHolder, setIsPlaceHolder] = useState('');
  const { showSectors } = useEntity();
  const [filterName, setFilterName] = useState<string>('');
  const [filterDate, setFilterDate] = useState<string>('');

  const [openModal, setOpenModal] = useState<boolean>(true);

  const { userManager, navigation } = useStore();

  const { data: _orders, isLoading, refetch: reFetchOrders } = useFetchAllOrdersForSchedule(entityId);

  const { data: sectors } = useGetSectorsActive(entityId, true);

  const { mutateAsync: deliveryOrderMutator, isLoading: isloadingDelivery } = useMutation(deliverOrder);
  const [selectedPrinter, setSelectedPrinter] = useState<string>('');

  const { handlePrint, printers } = usePrinter();

  const [availableLetters, setAvailableLetters] = useState<string[]>([]);
  const alphabetRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const handleOpenModal = (order: KitchenOrder) => {
    setSelectedOrder(order);

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    setSelectedOrder(null);
  };

  const filterOrdersByName = (order: any) => {
    if (!filterName) return true;
    const formattedFilterName = filterName.toLowerCase().replace(/\s+/g, '');
    const clientName = getClientNameForOrder(order).toLowerCase().replace(/\s+/g, '');
    return clientName.includes(formattedFilterName);
  };

  const filterOrdersByDate = (order: any) => {
    if (!filterDate) return true;
    const scheduleDate = new Date(order.scheduleFor).toISOString().split('T')[0];

    console.log("scheduleDate", scheduleDate);
    console.log("filterDate", filterDate);
    return scheduleDate === filterDate;
  };

  const handleDeliverOrder = async (id: string) => {
    await deliveryOrderMutator(id);
    await queryClient.invalidateQueries(['allOrdersScheduleOrder', userManager?.entity]);
  };

  const handleCancelOrder = (order: KitchenOrder) => {
    navigation.navigateTo("/login-admin", {
      state: {
        order,
        isSchedule: true,
      }
    });
  };

  const filterOrdersBySector = (order: any) => {
    if (sectorId === "all") return true;

    if (sectorId === "others") {
      return order.items?.some((item: any) => !item.segment);
    }

    return order.items?.some((item: any) => item.segment?.id === sectorId);
  };

  const loadOrders = async () => {
    if (_orders) {
      _orders.sort((firstOrder, secondOrder) => {
        const dataA: any = new Date(secondOrder?.scheduleFor || 0).getTime();
        const dataB: any = new Date(firstOrder?.scheduleFor || 0).getTime();
        return dataB - dataA;
      });

      const filteredOrders = _orders
        .filter((order: any) => filterOrdersBySector(order))
        .filter((order: any) => filterOrdersByName(order))
        .filter((order: any) => filterOrdersByDate(order));

      setOrders(filteredOrders);
    }
  };

  const formatList = () => {
    const listSectors = sectors?.map((_sector: Sector) => ({
      label: _sector.name,
      value: _sector.id,
    }));

    const listSortedSectors: any[] = listSectors?.sort((a: any, b: any) =>
      a.label.localeCompare(b.label));

    listSortedSectors.unshift({ label: "Todos", value: "all" });
    listSortedSectors.push({ label: "Outros", value: "others" });

    setSectorsList(listSortedSectors);
  };

  const filterOrders = (id: string) => {
    setSectorSelected(id);
    setSectorId(id);
    loadOrders();
  };

  const groupOrdersByInitial = (ordersName: KitchenOrder[]) => ordersName.reduce((acc: Record<string, KitchenOrder[]>, order) => {
    const initial = getClientNameForOrder(order)[0].toUpperCase();
    if (!acc[initial]) {
      acc[initial] = [];
    }
    acc[initial].push(order);
    return acc;
  }, {});

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const handleSelectLetter = (letter: string) => {
    const ref = alphabetRefs.current[letter];
    if (ref) {
      ref.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, async () => {
      await reFetchOrders();
    });
  }, []);

  useEffect(() => {
    (async () => {
      await loadOrders();
    })();
  }, [_orders, sectorId]);

  useEffect(() => {
    if (_orders) {
      const grouped = groupOrdersByInitial(_orders);
      setAvailableLetters(Object.keys(grouped).sort());
      setOrders(_orders);
    }
  }, [_orders]);

  useEffect(() => {
    if (userManager.entity) {
      setEntityId(userManager.entity);
    }
  }, [userManager]);

  useEffect(() => {
    if (sectors?.length) {
      formatList();
    }
  }, [sectors]);

  useEffect(() => {
    loadOrders();
  }, [filterName, filterDate]);

  const handlePrintOrder = async (orderId: string) => {
    const order = orders.find((item: any) => item.id === orderId);

    const formatCurrency = (amount: number): string => {
      const formattedAmount = (amount / 100).toFixed(2).replace('.', ',');
      return `R$ ${formattedAmount}`;
    };

    if (!order) {
      return;
    }

    const orderDetails = `
      ID: ${order.id}
      Cliente: ${order.client.name}
      Turma: ${order.client.identityNumber}
      Telefone: ${order.client.phone}
      Data do Pedido: ${new Date(order.createdAt).toLocaleString('pt-BR')}
      Agendado Para: ${order.isSchedule ? new Date(order.scheduleFor).toLocaleString('pt-BR') : 'N/A'}
      --------------------
      Itens:
      ${order.items.map((item: any) => `${item.quantity} - ${item.name} R$ ${formatCurrency(item.price)}`).join('\n')}
      --------------------
      Total: ${formatCurrency(order.totalAmount / 100)}
    `;

    await handlePrint(replaceSpecialCharacters(orderDetails), selectedPrinter);
  };

  return (
    <Container maxWidth={false} className={Styles.container}>
      <div className={Styles.table}>
        <div className={Styles.selectsWrapper}>
          {sectorsList?.length > 0 && showSectors && (
            <div className={Styles.filterSector}>
              <Select
                label="Setor"
                options={sectorsList}
                value={sectorSelected}
                onChange={(val) => filterOrders(val)}
              />
            </div>
          )}

          <div className={Styles.filterWrapper}>
            <CustomInput
              label="Buscar por nome"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
            <CustomInput
              label="Data de agendamento"
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>

          <div>
            {printers.length > 0 && (
              <SearchableInput
                value={selectedPrinter}
                onChange={(InputValue) => setSelectedPrinter(InputValue)}
                label="Impressora"
                data={printers.map((printer) => ({
                  label: printer,
                  value: printer,
                }))}
                onClick={() => setIsPlaceHolder('')}
                onBlur={() => setIsPlaceHolder('')}
                placeholder={<span className={Styles.placeHolder}>{isPlaceHolder}</span>}
                onSelect={(item) => setSelectedPrinter(item.label)}
              />
            )}
          </div>
        </div>
        <hr className={Styles.line} />

        {isLoading && (
          <div className={Styles.tableBody}>
            <div className={Styles.tableRow}>
              <Typography noWrap className={`${Styles.tableCell} ${Styles.tableColumn2}`}>Loading data...</Typography>
            </div>
          </div>
        )}
        {!isLoading && (
          <div className={Styles.tableWrapper}>
            <div className={Styles.tableBody}>
              {orders.map((order: KitchenOrder) => {
                const initial = getClientNameForOrder(order)[0].toUpperCase();

                return (
                  <div key={order.id}>

                    {!alphabetRefs.current[initial] && (
                    <div ref={(el) => { alphabetRefs.current[initial] = el; }} />
                    )}

                    <CardOrderSchedule
                      {...order}
                      name={getClientNameForOrder(order)}
                      key={order.id}
                      enrollment={order?.client?.identityNumber || ''}
                      orderAction={handleDeliverOrder}
                      cancelOrder={() => handleCancelOrder(order)}
                      selectOrder={() => handleOpenModal(order)}
                      handleProfileClick={() => setClientProfile(order.client)}
                      isLoading={isLoading || isloadingDelivery}
                      onPrint={(id: string) => handlePrintOrder(id)}
                      hasPrinter={printers.length > 0}
                    />
                  </div>
                );
              })}

            </div>
            <div className={Styles.alphabetIndex}>
              <AlphabetIndex letters={alphabet} availableLetters={availableLetters} onSelect={handleSelectLetter} />
            </div>
          </div>
        )}
      </div>

      {
        selectedOrder && (
          <EditRequest
            order={selectedOrder}
            open={openModal}
            onClose={handleCloseModal}
            disableProductsActionButtons
          />
        )
      }

      {
        clientProfile && (
          <ProfileModal
            open={!!clientProfile}
            onClose={() => setClientProfile(null)}
            {...clientProfile}
          />
        )
      }
    </Container>
  );
};
